import React, {
  useMemo,
  useRef,
  useCallback,
  useState,
  useContext,
} from 'react'
import { useFragment, graphql } from 'react-relay'
import { GestureResponderEvent } from 'react-native'

import { useNavigate } from 'react-router-dom'

import { YStack, XStack, Tooltip, getTokenValue, composeRefs } from 'tamagui'

import { format, utcToZonedTime } from 'date-fns-tz'

import { HOST_NAME, IMAGEKIT_URL } from '../../data/config/url'
import { ServerContext } from '../../data/ServerContext'

import { Button } from '../Button/Button'
import { H2, H3, Text, Paragraph, Strong } from '../Text'
import { Card } from '../Card'
import { Image } from '../Image'
import { UserPrimaryPhoto, UserDefaultPhoto } from '../User/UserPrimaryPhoto'
import { GuildPrimaryPhoto } from '../Guild/GuildPrimaryPhoto'
import { useCreateLinkPressHandler } from '../Link'
import { Icon } from '../Icon'

import { EventAttendButton } from './EventAttendButton'
import { EventAttendeesOverlap } from './EventAttendeesOverlap'

import { EventItemNew_event$key } from './__generated__/EventItemNew_event.graphql'
import { EventItemNew_query$key } from './__generated__/EventItemNew_query.graphql'
import { EventItemPresentations_event$key } from './__generated__/EventItemPresentations_event.graphql'
import { EventItemEventCardPhoto_event$key } from './__generated__/EventItemEventCardPhoto_event.graphql'

export const EventItem = React.forwardRef<
  unknown,
  {
    event: EventItemNew_event$key
    query: EventItemNew_query$key
    forceVertical?: boolean
    limitPresentations?: number
    showAttendButton?: boolean
    displayFullDate?: boolean
    displayVisibility?: boolean
  }
>(
  (
    {
      event,
      query,
      forceVertical = false,
      limitPresentations,
      showAttendButton = true,
      displayFullDate = false,
      displayVisibility = true,
      ...props
    },
    ref
  ) => {
    const data = useFragment(
      graphql`
        fragment EventItemNew_event on Event {
          name
          startAt

          prettyUrl

          hasExternalUrl
          hasVenue

          visibility
          coverPhoto {
            rowId
          }

          eventOwner: owner {
            __typename

            ... on Guild {
              name
              slugId

              primaryPhoto {
                rowId
              }

              myMembership {
                membershipLevel
              }

              ...GuildPrimaryPhotoNew_guild
            }

            ... on User {
              firstName
              lastName

              slugId

              isMe

              ...UserPrimaryPhotoNew_user
            }
          }

          attendeesByRelevance: eventAttendeesByRelevance(
            filter: {
              status: {
                in: [
                  ATTENDING_IN_PERSON
                  ATTENDING_VIRTUAL
                  WAITING_IN_PERSON
                  WAITING_VIRTUAL
                ]
              }
            }
            first: 8
          ) {
            totalCount

            ...EventAttendeesOverlap_eventAttendees
          }

          attendingCount: eventAttendees(
            filter: { status: { in: [ATTENDING_IN_PERSON, ATTENDING_VIRTUAL] } }
          ) {
            totalCount
          }

          waitingCount: eventAttendees(
            filter: { status: { in: [WAITING_IN_PERSON, WAITING_VIRTUAL] } }
          ) {
            totalCount
          }

          eventTicketingTiers {
            totalCount
          }

          ...EventAttendButtonNew_event
          ...EventItemEventCardPhoto_event
          ...EventItemPresentations_event
        }
      `,
      event
    )

    const queryData = useFragment(
      graphql`
        fragment EventItemNew_query on Query {
          ...EventAttendButtonNew_query

          viewer {
            authorizationLevel
          }
        }
      `,
      query
    )

    const owner = useMemo(() => {
      switch (data.eventOwner?.__typename) {
        case 'Guild': {
          return (
            <XStack gap='$3' alignItems='center'>
              {data.eventOwner.primaryPhoto ? (
                <GuildPrimaryPhoto guild={data.eventOwner} size='$7' />
              ) : (
                <YStack width='$7' alignItems='center'>
                  <Icon name='people' size='$6' color='$grey8' />
                </YStack>
              )}

              <Text size='$4'>{data.eventOwner.name}</Text>
            </XStack>
          )
        }
        case 'User': {
          return (
            <XStack gap='$3' alignItems='center'>
              <UserPrimaryPhoto user={data.eventOwner} size='$7' />

              <Text size='$4'>
                {data.eventOwner.firstName} {data.eventOwner.lastName}
              </Text>
            </XStack>
          )
        }
      }

      return null
    }, [data.eventOwner])

    const serverContext = useContext(ServerContext)

    const startAt = useMemo(() => {
      try {
        const zonedTime = serverContext?.timeZone
          ? utcToZonedTime(new Date(data.startAt), serverContext?.timeZone)
          : new Date(data.startAt)

        if (zonedTime.getMinutes() === 0) {
          return format(zonedTime, displayFullDate ? 'MMM do haa' : 'haa')
        }

        return format(zonedTime, displayFullDate ? 'MMM do h:mmaa' : 'h:mmaa')
      } catch (err) {}

      return format(
        new Date(data.startAt),
        displayFullDate ? 'MMM do h:mmaa' : 'h:mmaa'
      )
    }, [data.startAt, displayFullDate])

    const navigate = useNavigate()

    const { linkPressHandler, anchorRef } = useCreateLinkPressHandler(() => {
      navigate(`/events/${data.prettyUrl}`)
    })

    const hybridButtonOpened = useRef(false)
    const [hybridButtonOpenedState, setHybridButtonOpenedState] =
      useState(false)

    const handleCardPress = useCallback(
      (event: GestureResponderEvent) => {
        if (!hybridButtonOpened.current && !hybridButtonOpenedState) {
          linkPressHandler(event)
        } else {
          event.preventDefault()
          event.stopPropagation()
        }
      },
      [linkPressHandler, hybridButtonOpened, hybridButtonOpenedState]
    )

    const shouldShowAttendees = useMemo(() => {
      if (queryData.viewer?.authorizationLevel === 'ADMIN') {
        return true
      }

      if (
        data.eventOwner?.__typename === 'Guild' &&
        ['ORGANIZER', 'EDITOR'].includes(
          data.eventOwner.myMembership?.membershipLevel || ''
        )
      ) {
        return true
      }

      if (data.eventOwner?.__typename === 'User' && data.eventOwner.isMe) {
        return true
      }

      if (data.eventTicketingTiers.totalCount > 0) {
        return false
      }

      if (data.attendeesByRelevance.totalCount > 5) {
        return true
      }

      return false
    }, [data, queryData])

    return (
      <Card
        {...props}
        ref={composeRefs(anchorRef, ref)}
        tag='a'
        gap='$4'
        cursor='pointer'
        href={`/events/${data.prettyUrl}`}
        style={{
          textDecorationLine: 'none',
          willChange: 'box-shadow',
          transition: 'all 375ms cubic-bezier(0,0.5,1,1)',
        }}
        onPress={handleCardPress}
        padding='$5'
        paddingBottom={18}
        hoverStyle={{
          boxShadow:
            'rgba(10, 37, 64, 0.16) 0px 8px 16px -4px, rgba(10, 37, 64, 0.12) 0px 10px 30px -7px',
        }}
      >
        {data.coverPhoto ? (
          <XStack display='flex' $gtXs={{ display: 'none' }}>
            <EventCardPhoto
              event={data}
              marginLeft={-20}
              marginRight={-20}
              marginTop={-20}
            />
          </XStack>
        ) : null}

        <XStack gap='$8'>
          <YStack flex={1} gap='$4'>
            <YStack gap='$4' flex={1}>
              <YStack gap='$3'>
                <YStack gap='$3'>
                  <XStack gap='$2'>
                    <XStack gap='$2' flex={1}>
                      <Text color='$grey7' fontWeight='$6'>
                        {startAt}
                      </Text>

                      <JoiningInformation
                        hasVenue={data.hasVenue}
                        hasExternalUrl={data.hasExternalUrl}
                      />
                    </XStack>

                    {displayVisibility && data.visibility === 'UNLISTED' ? (
                      <YStack>
                        <Text
                          color='$darkBlue10'
                          fontWeight='$6'
                          size='$3'
                          backgroundColor='$darkBlue1'
                          paddingVertical='$1'
                          paddingHorizontal='$2'
                          borderRadius='$2'
                        >
                          Unlisted
                        </Text>
                      </YStack>
                    ) : null}
                  </XStack>

                  <H2 fontWeight='$6'>{data.name}</H2>
                </YStack>

                {owner}
              </YStack>

              <Presentations event={data} limit={limitPresentations} />

              {((!!data.attendeesByRelevance.totalCount &&
                shouldShowAttendees) ||
                showAttendButton) && (
                <YStack flex={1} justifyContent='flex-end'>
                  <XStack
                    gap='$4'
                    alignItems='center'
                    height={34}
                    marginLeft={2}
                    // marginLeft='$1'
                    marginBottom={-2}
                  >
                    {!!data.attendeesByRelevance.totalCount &&
                    shouldShowAttendees ? (
                      <EventAttendeesOverlap
                        eventAttendees={data.attendeesByRelevance}
                        attendingCount={data.attendingCount.totalCount}
                        waitingCount={data.waitingCount.totalCount}
                        flex={1}
                      />
                    ) : (
                      <XStack flex={1} />
                    )}

                    {showAttendButton && (
                      <XStack $gtXs={{ display: 'none' }}>
                        <EventAttendButton
                          event={data}
                          query={queryData}
                          onOpenChange={(open) => {
                            hybridButtonOpened.current = open
                            setHybridButtonOpenedState(open)
                          }}
                        />
                      </XStack>
                    )}
                  </XStack>
                </YStack>
              )}
            </YStack>

            {/* {showAttendButton && (
              <XStack
                {...(forceVertical
                  ? { display: 'flex' }
                  : { display: 'flex', $gtXs: { display: 'none' } })}
              >
                <EventAttendButton
                  event={data}
                  query={queryData}
                  onOpenChange={(open) => {
                    hybridButtonOpened.current = open
                    setHybridButtonOpenedState(open)
                  }}
                />
              </XStack>
            )} */}
          </YStack>

          {!forceVertical && (
            <YStack
              gap='$5'
              display='none'
              $gtXs={{ display: 'flex', maxWidth: 200 }}
            >
              <YStack flex={1}>
                <EventCardPhoto event={data} />
              </YStack>

              {showAttendButton && (
                <XStack justifyContent='flex-end'>
                  <EventAttendButton
                    event={data}
                    query={queryData}
                    onOpenChange={(open) => {
                      hybridButtonOpened.current = open
                      setHybridButtonOpenedState(open)
                    }}
                  />
                </XStack>
              )}
            </YStack>
          )}
        </XStack>
      </Card>
    )
  }
)

const EventCardPhoto = Image.styleable<
  HTMLImageElement,
  {
    event: EventItemEventCardPhoto_event$key
  }
>(({ event, ...props }, ref) => {
  const data = useFragment(
    graphql`
      fragment EventItemEventCardPhoto_event on Event {
        name
        prettyUrl
        visibility

        coverPhoto {
          rowId
          contentType
        }
      }
    `,
    event
  )

  if (!data.coverPhoto) {
    return (
      <Image
        {...props}
        ref={ref}
        alt={`Event Cover Photo for ${data.name}`}
        src={`${HOST_NAME}/social/event/${data.prettyUrl}/card.svg`}
        width='calc(100% + 40px)'
        aspectRatio={2}
        borderRadius='$6'
        borderBottomLeftRadius={0}
        borderBottomRightRadius={0}
        $gtXs={{
          width: '100%',
          borderRadius: 14,
          borderBottomLeftRadius: 14,
          borderBottomRightRadius: 14,
        }}
      />
    )
  }

  return (
    <>
      <Image
        {...props}
        ref={ref}
        alt={`Event Cover Photo for ${data.name}`}
        src={`${IMAGEKIT_URL}/tr:w-576,dpr-3/${
          data.coverPhoto.rowId
        }.${data.coverPhoto.contentType.toLowerCase()}`}
        borderRadius='$6'
        borderBottomLeftRadius={0}
        borderBottomRightRadius={0}
        width='calc(100% + 40px)'
        $gtXs={{
          display: 'none',
        }}
      />

      <Image
        {...props}
        ref={ref}
        alt={`Event Cover Photo for ${data.name}`}
        display='none'
        borderRadius={14}
        $gtXs={{
          display: 'flex',
        }}
        width={200}
        src={`${IMAGEKIT_URL}/tr:w-200,dpr-3/${
          data.coverPhoto.rowId
        }.${data.coverPhoto.contentType.toLowerCase()}`}
      />
    </>
  )
})

const JoiningInformation = ({
  hasVenue,
  hasExternalUrl,
}: {
  hasVenue: boolean | null
  hasExternalUrl: boolean | null
}) => {
  if (hasVenue && hasExternalUrl) {
    return (
      <Text color='$grey7' fontWeight='$5'>
        In-Person & Online
      </Text>
    )
  }

  if (hasVenue) {
    return (
      <Text color='$grey7' fontWeight='$5'>
        In-Person
      </Text>
    )
  }

  if (hasExternalUrl) {
    return (
      <Text color='$grey7' fontWeight='$5'>
        Online
      </Text>
    )
  }

  return null
}

const Presentations = ({
  event,
  limit,
}: {
  event: EventItemPresentations_event$key
  limit?: number
}) => {
  const data = useFragment(
    graphql`
      fragment EventItemPresentations_event on Event {
        eventItemPresentations: presentations(
          orderBy: [CREATED_AT_ASC, ID_ASC]
        ) {
          edges {
            node {
              title
              rowId

              presenterFirstName
              presenterLastName

              presenter {
                firstName
                lastName

                ...UserPrimaryPhotoNew_user
              }
            }

            cursor
          }
        }
      }
    `,
    event
  )

  const limitedEdges = useMemo(() => {
    if (limit) {
      return data.eventItemPresentations.edges.slice(0, limit)
    }

    return data.eventItemPresentations.edges
  }, [data.eventItemPresentations, limit])

  if (!limitedEdges.length) {
    return null
  }

  return (
    <YStack gap='$3' flex={1}>
      {limitedEdges.map((edge) => {
        return (
          <XStack gap='$3' key={edge.cursor}>
            <XStack marginTop={2} width='$7' justifyContent='center'>
              {edge.node.presenter ? (
                <UserPrimaryPhoto user={edge.node.presenter} size='$6' />
              ) : (
                <UserDefaultPhoto
                  alt={`Primary Photo for ${edge.node.presenterFirstName} ${edge.node.presenterLastName}`}
                  rowId={edge.node.rowId}
                  parsedSize={6 * 4}
                  width={6 * 4}
                  height={6 * 4}
                  borderRadius={6 * 4}
                />
              )}
            </XStack>

            <YStack gap={2} flex={1}>
              <Strong
              // $gtSm={{
              //   maxWidth: '85%',
              // }}
              >
                {edge.node.title}
              </Strong>

              <Paragraph size='$4' color='$grey7'>
                by{' '}
                {edge.node.presenter
                  ? `${edge.node.presenter.firstName} ${edge.node.presenter.lastName}`
                  : `${edge.node.presenterFirstName} ${edge.node.presenterLastName}`}
              </Paragraph>
            </YStack>
          </XStack>
        )
      })}
    </YStack>
  )
}

const AttendanceText = ({
  attendingCount,
  waitingCount,
}: {
  attendingCount: number
  waitingCount: number
}) => {
  if (attendingCount && waitingCount) {
    return (
      <YStack marginLeft={-2}>
        <Paragraph size='$4'>{attendingCount} Attending</Paragraph>

        <Paragraph size='$4'>{waitingCount} Waiting</Paragraph>
      </YStack>
    )
  }

  if (attendingCount) {
    return (
      <Paragraph size='$4' marginLeft={-2}>
        {attendingCount} Attending
      </Paragraph>
    )
  }

  if (waitingCount) {
    return (
      <Paragraph size='$4' marginLeft={-2}>
        {waitingCount} Waiting
      </Paragraph>
    )
  }

  return null
}
